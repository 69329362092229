<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">
      <div class="d-flex pt-3 ">
        <v-text-field v-model="filtroUsuario" outlined dense class="me-2" label="Usuario" hide-details>
        </v-text-field>
  
        <v-text-field v-model="filtroBodega" class="me-2"  outlined dense label="Nombre bodega"
        hide-details>
        </v-text-field>
  
        <v-autocomplete v-model="filtroEstado" :items="informacionEstados" outlined dense label="Estado"
        hide-details>
        </v-autocomplete>
  
        <v-tooltip left color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="abrirDialogRelacion()" small class="ms-2" fab color="success" v-bind="attrs" v-on="on" >
              <v-icon> add </v-icon>
            </v-btn>
          </template>
          <span>Agregar relación</span>
        </v-tooltip>
      </div>

      <v-dialog v-model="dialogRelacion" persistent transition="dialog-bottom-transition" max-width="35rem">
        <v-card>
          <v-toolbar color="primary" dark>
              <span class="text-h6">Agregar relación</span>
          </v-toolbar>

          <v-card-text style="padding-top: 0.7rem;">
              <v-form class="mt-3">
                <v-autocomplete class="mb-2" @keyup="delayListarUsuarios($event.target.value)" :items="listaUsuarios"
                    outlined dense label="Seleccione el usuario" return-object v-model="usuarioSeleccionado"
                    :item-text="mostrarUsuarios"></v-autocomplete>
                <v-autocomplete class="mb-2" @keyup="listarPuntoVenta()" :items="listaPuntoVenta"
                    return-object v-model="puntoVentaSeleccionado"
                    :item-text="mostrarPuntoVenta" outlined dense label="Seleccione el punto de venta" > </v-autocomplete>
                <v-autocomplete :items="listaBodegas" return-object v-model="bodegaSeleccionada"
                    :item-text="mostrarBodegas"
                    outlined dense label="Seleccione la bodega" > 
                </v-autocomplete>
              </v-form>
              <div class="d-flex justify-end mt-2">
                <v-btn @click="cerrarDialogRelacion()" color="error" text>
                    CERRAR
                </v-btn>
                <v-btn @click="crearNuevaRelacion()" depressed color="success" text>
                  Guardar
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
    
        <v-data-table :headers="headers" :items="listaRelaciones" :footer-props="footerProps"
            class="elevation mt-4" height="66vh" :loading="loading" loading-text="Cargando..."
            :items-per-page="itemsPorPagina" :page="pagina" :server-items-length="totalElementos"
            @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p">
          <template v-slot:footer.page-text="items">
                  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <div class="d-flex align-center">
              <v-icon :color="item.eliminado === true ? 'red' : 'green darken-2'">
                {{ item.eliminado === true ? 'cancel' : 'check_circle' }}
              </v-icon>
              {{ item.eliminado === true ? 'Inactivo' : 'Activo' }}
            </div>
          </template>
          <template v-slot:[`item.eliminar`]="{ item }">
            <section class="d-flex">
              <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon :color="item.eliminado === false ? 'error' : 'success'" @click="modalActivoDesactivo(item)"
                    v-bind="attrs" v-on="on">
                    <v-icon>{{ item.eliminado === false ? 'disabled_by_default' : 'fact_check' }}</v-icon>
                  </v-btn>
                </template>
              <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>
            </section>
          </template>
          <!-- Botón para ver la trazabilidad -->
          <template v-slot:[`item.trazabilidad`]="{ item }">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="blue" v-bind="attrs" v-on="on" @click="listarTrazabilidad(item)">
                  <v-icon>backup_table</v-icon></v-btn>
              </template>
              <span>Trazabilidad</span>
            </v-tooltip>
          </template>
          <template v-slot:footer.page-text="items">
            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
          </template>
        </v-data-table>
        <v-dialog v-model="dialogEstado" max-width="360">
          <v-card>
            <v-card-title style="background-color: #1867c0 !important;color: white;">
              {{
              filaSeleccionada.eliminado === false ? '¿Desea desactivar la relación?' : '¿Desea activar la relación?'
              }}
            </v-card-title>
              <div class="d-flex justify-end pa-3">
                <v-btn color="success" @click="editarEstado()"> Si </v-btn>
                <v-btn color="error" text @click="dialogEstado = false"> No </v-btn>
              </div>
          </v-card>
        </v-dialog>
      <ModalTrazabilidad :trazabilidadData="trazabilidadData" :trazaDe="trazaDe" />
    </v-main>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import ModalTrazabilidad from '@/components/ModalTrazabilidad.vue';

const debounce = function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
      clearTimeout(timeoutID);
      let args = arguments;
      let that = this;
      timeoutID = setTimeout(function () {
        fn.apply(that, args);
      }, delay);
    };
  };

export default {
  name: "UsuariosBodegas",
  components: { ModalTrazabilidad },

  data() {
    return {  
      listaRelaciones:[],
      pagina: 1,
      itemsPorPagina: 10,
      totalElementos: 0,
      dialogEstado: false,
      dialogRelacion: false,
      dialogCambio: false,
      loading: false,
      filaSeleccionada: {},
      listaUsuarios:[],
      listaBodegas:[],
      listaBodegasPorUsuario:[],
      listaPuntoVenta:[],
      estadoEditado: false,
      filtroUsuario: "",
      filtroBodega: "",
      filtroEstado: "",
      usuarioSeleccionado: "",
      puntoVentaSeleccionado: {},
      bodegaSeleccionada: {},
      bodegaDestino: {},
      trazabilidadData:[],
      trazaDe: "",
      footerProps: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Items por página:',
      'show-current-page': true,
      'show-first-last-page': true,
      },
      headers: [
      { text: "USUARIO", value:"nombreUsuario", align:"center", width:"20%", sortable: false },
      { text: "BODEGA", value:"nombreBodega", sortable: false, width:"40%", align:"center" },
      { text: "ESTADO", value:"estado", sortable: false, width: "20%" },
      { text: "ACCIONES", value:"eliminar", align: "center", sortable: false, width:"10%"},
      { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false, }
      ],
      informacionEstados:[
      {text: "Activo", value: "false"},
      {text: "Inactivo", value: "true"},
      ],
    }
  },

  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad", "enterprise"]),
  },

    created() {
       this.getRelacionUsuarioBodega(); 
       this.listarUsuarios();
       this.listarPuntoVenta();
    },

  methods: {

    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
        
    getRelacionUsuarioBodega(){
      this.listaRelaciones = [];
      this.loading = true;
      this.$http.get("/msa-administration/usuarioBodega/listarRelacion", {
        params:{
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          usuario: this.filtroUsuario,
          bodega: this.filtroBodega,
          estado: this.filtroEstado
        }
      }).then((result) => {
        this.loading = false;
        this.listaRelaciones = result.data.content
        this.totalElementos = result.data.totalElements
      }).catch(() => {
        this.loading = false;
      })
    },

    modalActivoDesactivo(item) {
      this.dialogEstado = true;
      this.filaSeleccionada = item;
      item.eliminado === false ? this.estadoEditado = true : this.estadoEditado = false;
    },

    editarEstado(){
      this.$http.put("/msa-administration/usuarioBodega/editarEstado", {
        idUsbo: this.filaSeleccionada.idRelacion,
        eliminado: this.estadoEditado,
        usuarios: {
            usuario: this.filaSeleccionada.nombreUsuario,
        },
        bodegas: {
            bodegasId: {
              idEmpresa: this.enterprise.code,
              codigoBodega: this.filaSeleccionada.codigoBodega,
            },
            puntoVenta:{
              puntoVentaId:{
                codigoPuntoVenta: this.filaSeleccionada?.puntoVenta
              }
            } 
        }
      }).then((result) => {
        if(result.data.response == 1){
            this.getRelacionUsuarioBodega()
            this.dialogEstado = false
            this.showSuccess(result.data.mensaje)
        }else{
            this.getRelacionUsuarioBodega()
            this.dialogEstado = false
            this.showError(result.data.mensaje);
        }
      }).catch((error) => { 
        this.showError(error)
      })
    },

    crearNuevaRelacion(){
      if(this.usuarioSeleccionado != null && this.puntoVentaSeleccionado?.puntoVentaId?.codigoPuntoVenta != null
        && this.bodegaSeleccionada?.bodegasId?.codigoBodega != null){
        this.$http.post("/msa-administration/usuarioBodega/guardarRelacion", {
          usuarios: {
              usuario: this.usuarioSeleccionado,
          },
          bodegas: {
            bodegasId: {
              idEmpresa: this.enterprise.code,
              codigoBodega: this.bodegaSeleccionada?.bodegasId?.codigoBodega,
            },
            puntoVenta:{
              puntoVentaId:{
                codigoPuntoVenta: this.puntoVentaSeleccionado?.puntoVentaId?.codigoPuntoVenta
              }
            } 
          },
        }).then((result) => {
          if(result.data.response == 1 || result.data.response == 2){
              this.getRelacionUsuarioBodega()
              this.cerrarDialogRelacion()
              this.showSuccess(result.data.mensaje)
          }else{
            this.getRelacionUsuarioBodega()
            this.cerrarDialogRelacion()
            this.showError(result.data.mensaje)
          }
        }).catch((error) => {
          this.showError(error)
        })
      }else{
          this.showError("Debe llenar todos los campos");
      }
    },

    listarUsuarios(usuario){
      this.$http.get("/msa-administration/usuarioBodega/listarUsuario", {
        params:{
            usuario: usuario
        }
      }).then((result) => {
        this.listaUsuarios = result.data;
      })
    },

    delayListarUsuarios: debounce(function(param) {
      this.listarUsuarios(param)
    }, 500),

    mostrarUsuarios(item){
      return item?.usuario
    },

    listarPuntoVenta(){
      this.$http.get("/msa-administration/usuarioBodega/listarPuntoVenta").then((result) => {
        this.listaPuntoVenta = result.data;
      })
    },

    listarTrazabilidad(relacion) {
      this.$http.get(`/msa-administration/usuarioBodega/listarTrazabilidad`, {
        params:{
          idUsbo: relacion.idRelacion
        }
      }).then(({ data }) => {
          data.forEach(element => {
            element.time = this.formatoFechaTrazabilidad(element.time);
            if (element.campo == 'eliminado') element.campo = 'Eliminado';
            if (element.anterior == true) element.anterior = 'Inactivo';
            if (element.anterior == false) element.anterior = 'Activo';
            if (element.nuevo == false) element.nuevo = 'Activo';
            if (element.nuevo == true) element.nuevo = 'Inactivo';
          })
          this.trazabilidad.visible = true;
          this.trazaDe = `${relacion.nombreUsuario} - ${relacion.nombreBodega}(${relacion.codigoBodega})`
          this.trazabilidadData = data.reverse();
        }).catch(err => console.log(err));
    },

    formatoFechaTrazabilidad(dateTimeString) {
      const fecha = new Date(dateTimeString);
      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
      const formatoHoras = horas % 12 || 12;
      const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
      const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const formatoDia = fecha.getDate().toString().padStart(2, '0');
      const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos}${ampm}`;
      return formatoFechaHora;
    },

    mostrarPuntoVenta(item){
      return item?.nombrePuntoVenta + " - " + item?.puntoVentaId?.codigoPuntoVenta
    },

    listarBodegas(){
      if(this.puntoVentaSeleccionado?.puntoVentaId?.codigoPuntoVenta != null){
        this.$http.get("/msa-administration/usuarioBodega/listarBodega", {
        params:{
          puntoVenta: this.puntoVentaSeleccionado?.puntoVentaId?.codigoPuntoVenta
        }
        }).then((result) => {
          this.listaBodegas = result.data;
        })
      }
    },

    listarBodegasPorUsuario(){
        this.$http.get("/msa-administration/usuarioBodega/listaBodegaPorUsuario", {
        params:{
          usuario: this.usuarioSeleccionado
        }
        }).then((result) => {
          this.listaBodegasPorUsuario = result.data;
        })
    },

    mostrarBodegas(item){
      return item?.nombreBodega + " - " + item?.bodegasId?.codigoBodega
    },

    mostrarBodegasPorUsuario(item){
      return item?.nombreBodega + " - " + item?.codigoBodega
    },

    abrirDialogRelacion(){
      this.listarUsuarios();
      this.listarPuntoVenta();
      this.dialogRelacion = true;
    },

    cerrarDialogRelacion(){
      this.dialogRelacion = false;
      this.usuarioSeleccionado = null;
      this.puntoVentaSeleccionado = {};
      this.bodegaSeleccionada = {};
    },
  },

  watch:{
    "filtroUsuario": debounce(function () {
      if(this.dialogCambio === false){
        this.getRelacionUsuarioBodega()
      }
    }, 500),

    "usuarioSeleccionado": debounce(function () {
      if(this.dialogCambio === true){
        this.listarBodegasPorUsuario()
      }
    }, 500),

    "filtroBodega": debounce(function () {
      this.getRelacionUsuarioBodega()
    }, 500),

    "filtroEstado": function () {
      this.getRelacionUsuarioBodega()
    },

    "puntoVentaSeleccionado.puntoVentaId.codigoPuntoVenta": function () {
      this.listarBodegas()
      if(this.puntoVentaSeleccionado?.puntoVentaId?.codigoPuntoVenta == null){
        this.listaBodegas = [];
        this.bodegaSeleccionada = {};
      }
    },
  }
}
</script>

<style scoped>

  ::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
  }
  
  ::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
  }
  
  ::v-deep .elevation .v-data-footer {
    width: 100%;
  }
  
  ::v-deep .elevation .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
  }
  
  ::v-deep .elevation .v-data-table__wrapper {
    border: 1px solid #f7f6f6;
  }
  
  ::v-deep #objeto {
    margin: 5px;
  }

  ::v-deep .v-messages {
  display: none;
  }

  ::v-deep .v-text-field__details{
    display: none;
  }
</style>